import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import SockJs from 'sockjs-client';
import {Stomp} from "@stomp/stompjs";

import {dateFormat} from '../util/DateFormatter';



//import qrcode from './An-example-of-QR-code.png'

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, producturl, rtcom}  from '../../environment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faQrcode,faBox, faCalendarCheck, faStore, faLink} from '@fortawesome/free-solid-svg-icons';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';


function PurchaseTickets(){

    const history = useNavigate();
    

    const[events, setEvents]=useState(null);
    const[userData, setUserData]=useState(null);

    const getRemoteData=(url)=>{
        return new Promise((resolve, reject)=>{
            fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
        })
    }

    const routeClick=(path)=>{
        history(path, {replace:true})
    }

    const isActive=(e)=>{
        return dateFormat.eventCompleted(e.eventdates[0].epochtime);
    }

    const handlePurchaseEvent=(eventPurchases)=>{

        console.info("handlePurchaseEvent->eventPurchases:");
        console.info(eventPurchases);
        
        var tempEventIds=[];
        eventPurchases?.map((purchaseTicket)=>{
            tempEventIds.push(purchaseTicket?.orderInfo?.purchase.products[0].eventId);
        })

        const uniqueEventIds = tempEventIds.filter((x, i, a) => a.indexOf(x) == i);
        const prods_data=[]
        uniqueEventIds.map((eid)=>{
            prods_data.push(getRemoteData(producturl+'/api/prod/event/'+eid))
        });
 
        Promise.all(prods_data).then((eventsdata)=>{
            var tempeventdata=[];

            eventsdata.map((edata)=>{
                console.info("handlePurchaseEvent->edata:");
                console.info(edata);
                tempeventdata.push(edata);
            })

            setEvents(tempeventdata);
        });
    }


    useEffect(() => {
        console.info("PurchaseTicket->UseEffect: ")
        const userdata=JSON.parse(localStorage.getItem('userdata'));
        setUserData(userdata);
        console.info(userdata)

        //GET TICKET PURCHASE
        new RestServer().setUrl(conurl+'/api/cms/getpurchaseticket/'+userdata.userid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            console.info("getpurchaseticket->res.data")
            console.info(res.data)
            handlePurchaseEvent(res.data);
        },[]);
     
    },[]);
    
    return(
        <React.Fragment>
           <Row>
                <Col className="font-size-150 font-heavy8"> My Event Tickets</Col>
            </Row>
            <Row className="p-t-25 p-b-50">
                <Col>
                    <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>
                    <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-25"><FontAwesomeIcon icon={faCalendarCheck} />&nbsp;&nbsp;&nbsp;&nbsp;Next</button>
                    <button className="icon-text-container-1 m-l-10 font-heavy font-size-75 p-10 p-r-25"><FontAwesomeIcon icon={faCalendarCheck} />&nbsp;&nbsp;&nbsp;&nbsp;Upcoming</button>
                    <button className="icon-text-container-1 m-l-10 font-heavy font-size-75 p-10 p-r-25"><FontAwesomeIcon icon={faCalendarCheck} />&nbsp;&nbsp;&nbsp;&nbsp; Over</button>
                 </Col>
            </Row>
            {
                events?.map((event)=>{
                const ticloc = "/listeventtickets/".concat(event?.eventId);
                const prodloc="/listeventproducts/".concat(event?.eventId);
                return <Row className="p-b-25 ">
                    <Col lg={10} sm={12} xs={12} md={10}>
                    <Row className="glass-container-2 p-10 zindex-100" >
                        <Col lg={3} sm={12} xs={12} md={10}><img src={event?.eventimage[0].imageloc+event?.eventimage[0].imagename} className="img-container-1 p-b-20"/></Col>

                        <Col>
                            <Row><Col className="font-size-150 font-heavy8">{event?.eventname}</Col></Row>
                            <Row><Col className="p-t-5 font-size-100 clr-1 font-heavy8">{dateFormat.getformateddate(event?.eventdates[0].epochtime)} - {isActive(event)?"CLOSED":""}</Col></Row> 
                            <Row><Col className="p-t-10 font-size-100">{event?.eventdescription}</Col></Row>
                                       
                            <Row className="p-t-50 p-b-25 zindex-200">
                                <Col >
                                    <button className="icon-container-1  zindex-300" onClick={()=>{routeClick(ticloc)}}>
                                        <FontAwesomeIcon icon={faQrcode} />
                                        <span className="icon-container-1-title zindex-300">Event Tickets
                                            <div className="icon-container-1-title-pointer  zindex-300"></div>
                                        </span>
                                    </button>         
                                </Col>
                                <Col >
                                    <button className="icon-container-1" onClick={()=>{routeClick(ticloc)}}>
                                        <FontAwesomeIcon icon={faStore} />
                                        <span className="icon-container-1-title">Vendor Tickets
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>         
                                </Col>
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{routeClick(prodloc)}}>
                                        <FontAwesomeIcon icon={faBox} />
                                        <span className="icon-container-1-title">Event Products<div className="icon-container-1-title-pointer"></div></span>
                                    </button>
                                </Col>
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{routeClick("/eventlinkmanagement/"+event?.eventId)}}>
                                        <FontAwesomeIcon icon={faLink} />
                                        <span className="icon-container-1-title">Link Management<div className="icon-container-1-title-pointer"></div></span>
                                    </button>
                                </Col>
                               
                            </Row>

                    
                </Col>
            </Row>
            </Col></Row>
           
            })
            }
        </React.Fragment>
    );
}
export default PurchaseTickets;